import _ from "lodash";
import {v4 as uuidv4} from 'uuid';
import TraceabilityService from "@/services/TraceabilityService";

export default {
  FETCH_TRACE({commit}, payload) {
    return new Promise(async (resolve, reject) => {
      let traceabilityData = null;


      await TraceabilityService.fetchTraceability({...payload}).then(res => {

        let data = res.data
        traceabilityData = {
          "publicIp": null,
          "otpCode": data.otpCode,
          "reportId": data.reportId
        }

        let first = {
          ...data.first,
          storageDeviceList: _.map(data.first.storageDeviceList, (storage) => {
            return {
              ...storage,
              uuid: uuidv4()
            }
          })
        }

        let last = _.orderBy(_.map(data.last, (l) => {
          return {
            ...l,
            storageDeviceList: _.map(l.storageDeviceList, (storage) => {
              return {
                ...storage,
                uuid: uuidv4()
              }
            })
          }
        }),['storageDeviceTypeId'],['asc'])

        commit('SET_FIRST', first)
        commit('SET_SECONDS', last)
        commit('SET_STAGES', [first, ...last])

        resolve(res.data)
      }).catch(err => {
        console.log(err)
        reject(err)
      });

      await fetch('https://api.ipify.org?format=json')
          .then(response => response.json())
          .then(data => {
            traceabilityData.publicIp = data.ip;
          })
          .catch(error => {
            console.log('Error:', error);
          });

      await TraceabilityService.fetchVisit(traceabilityData).then(res => {}).catch(err => {
        console.log(err)
        reject(err)
      })

    })
  },

  FETCH_PROFILE({commit, getters}, payload) {
    let profile = _.cloneDeep(getters.GET_PROFILE)

    return new Promise((resolve, reject) => {
      TraceabilityService.fetchLogo(payload).then(res => {
        profile.secondaryLogo = res.data.iconBase64
        commit('SET_PROFILE', profile)
        resolve(res.data)
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  },

  async FETCH_CERTIFICATE({commit}, payload) {
    const { data }  = await TraceabilityService.fetchCertificate(payload.id)

    try {
      if (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(data.type)) {
        const file = new Blob([data], { type: "image/jpeg" })
        commit('SET_BLOB', URL.createObjectURL(file))
      } else {
        const file = new Blob([data], { type: "application/pdf" })
        commit('SET_BLOB', URL.createObjectURL(file))
      }
    } catch (e) {
      console.log(e)
    }

  },

  FETCH_BLOCKCHAIN_HASH({}, payload) {
    return new Promise((resolve, reject) => {
      TraceabilityService.fetchBlockchainHash(payload).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      });
    })
  },

  FETCH_URL_REDIRECT({commit}, payload) {
    return new Promise((resolve, reject) => {
      TraceabilityService.fetchUrlRedirect(payload).then(res => {
        commit('SET_REDIRECT', res.data.url)
        resolve(res.data)
      }).catch(err => {
        commit('SET_REDIRECT', "https://soudealgodao.com.br/souabr/")
        resolve(err)
      });
    })
  },

  FETCH_URL_DATA({commit}, payload) {
    return new Promise((resolve, reject) => {
      TraceabilityService.fetchUrlData(payload).then(res => {
        commit('SET_QUERY_PARAMS', res.data)
        resolve(res.data)
      }).catch(err => {
        commit('SET_REDIRECT', "https://soudealgodao.com.br/souabr/")
        resolve(err)
      });
    })
  }
};