import router from '@/router'
import * as types from './mutations-type.js'
import { default as axios } from '@/services/HttpService'
import AuthService from '@/services/AuthService'
import TranslationService from '@/services/TranslationService'

export default {
  [types.SET_LOADING](state, data) {
    state.loading = data
  },

  [types.SET_LOGIN](state, data) {
    localStorage.setItem('token', data.token)
    localStorage.setItem('refresh_token', data.refresh_token)
    state.token = data.token
    state.refresh_token = data.refresh_token
    AuthService.getProfile().then((resp) => {
      state.roles = resp.data.roles
      localStorage.setItem('roles', JSON.stringify(resp.data.roles))
      TranslationService.changeLang(resp.data.language)
      localStorage.setItem('profile', JSON.stringify(resp.data))
      // this.dispatch('general/getInitData', [data[1], false])
    })
  },

  [types.SET_ITEM_SELECTED](state, data) {
    state.credentials = data.data
  },

  [types.SET_LOGOUT](state) {
    state.token = ''
    localStorage.removeItem('token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('profile')
    localStorage.removeItem('roles')
    state.roles = []
    delete axios.client.defaults.headers.common['Authorization']
    this.dispatch('general/setLayout', 'Login')
    router.push({ name: 'login' })
  },

  [types.SET_REFRESH](state, data) {
    const decode = AuthService.parseJwt(data.token)
    localStorage.setItem('token', data.token)
    localStorage.setItem('refresh_token', data.refresh_token)
    // state.roles = decode.roles;
    state.token = data.token
    state.refresh_token = data.refresh_token
    state.exp = decode.exp
    state.email = decode.name
    state.refresh = false
  },

  [types.SET_LOAD_INIT_DATA](state) {
    if (state.token === '')
      if (!router.history.pending) router.push({ name: 'login' })
  },
}
