import * as types from './mutations-type'

export default {
    [types.SET_CURRENT_ROUTE](state, payload) {
        state.currentRoute = payload
    },

    [types.SET_LOCALE](state, payload) {
        state.locale = payload
    },

    [types.SET_NAVIGATION_DRAWER](state, payload) {
        state.drawer = payload
    },

    [types.SET_VIEW](state, payload) {
        state.view = payload
    },

    [types.SET_DIALOG_LOCATION](state, payload) {
        state.dialog.location = payload
    }
};