import axios, { Cancel } from 'axios'
import store from '@/store'
import AuthService from './AuthService'

export const client = axios.create({
  baseURL: process.env.VUE_APP_BACK_API,
  timeout: 1000000,
})

export const s3 = axios.create({
  baseURL: '',
  timeout: 1000000,
})

export const clientServer = axios.create({
  baseURL: process.env.VUE_APP_BACK_SERVER,
  timeout: 1000000,
})

export const mapBoxAPI = axios.create({
  baseURL: process.env.VUE_APP_MAPBOX_API,
  timeout: 1000000,
})

export const maps = axios.create({
  baseURL: process.env.VUE_APP_MAP_SERVER,
  timeout: 1000000,
})

/*client.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('token')
    const refresh_token = localStorage.getItem('refresh_token')
    if (token) {
      const time_token = AuthService.parseJwt(token)
      const time_refresh = AuthService.parseJwt(refresh_token)
      const timeLeft_token = AuthService.isTokenExpired(time_token.exp)
      const timeLeft_refresh = AuthService.isTokenExpired(time_refresh.exp)
      if (
        timeLeft_token < 900 &&
        timeLeft_refresh > 120 &&
        !store.getters['auth/refresh']
      ) {
        store.state.login.refresh = true
        store.dispatch('auth/refresh', [refresh_token, token])
      } else if (timeLeft_refresh <= 120 || timeLeft_token < 120) {
        throw new axios.Cancel('tokenExpire')
      }
      if (!config.url.includes('/v1/profile/refresh_token'))
        config.headers['x-auth-token'] = `${token}`
    }

    return config
  },
  (error) => Promise.reject(error)
)*/

/*client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error instanceof Cancel) {
      if (error.message === 'cancelRequest') {
        return Promise.reject(error)
      } else if (error.message === 'tokenExpire') {
        store.commit('auth/LOGOUT')
        error.message = 'cancelRequest'
      }
    } else {
      if (error.message !== 'Network Error') {
        const { status } = error.response
        if (status === 401 && store.getters['auth/isLoggedIn']) {
          store.dispatch('auth/logout')
        } else if (status === 403) {
          return Promise.reject(error)
        }
      } else {
        Promise.reject(error)
      }
    }
    return Promise.reject(error)
  }
)*/

export default { s3, client, clientServer, mapBoxAPI, maps }
