import VuexPersistence from 'vuex-persist'

const vuexTrace = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    // traceability store
    first: state.trace.first,
    seconds: state.trace.seconds,
    stages: state.trace.stages,
    // ui store
    ui: state.ui
  }),
})

export default vuexTrace