import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import GoogleTranslateSelect from '@google-translate-select/vue2';

Vue.config.productionTip = false

/* Global Styles */
import '@/assets/css/styles.scss'

// Global Filters
import filters from './filters';
Vue.use(filters);

/* Vue Toast */
import Toast from "vue-toastification";
const options = {
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false
};

Vue.use(Toast, options);

/* Vee-Validate */
import {ValidationObserver, ValidationProvider} from "vee-validate";
import * as validators from '@/validators/validators'

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Vue.use('validators', validators)
Vue.use(GoogleTranslateSelect);

import titleMixin from '@/mixins/titleMixin'
Vue.mixin(titleMixin)

if (process.env.NODE_ENV === 'production') {
  console.log = function () {} // override the log function to hide logs
  console.error = function () {} // override the log function to hide logs
  console.warn = function () {} // override the log function to hide logs
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
