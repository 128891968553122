import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import trace from './trace'
import ui from './ui'
import vuexTrace from '@/plugins/vuex-persist'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    namespaced: true,
    auth,
    trace,
    ui,
  },
  plugins: [vuexTrace.plugin],
})

export default store
