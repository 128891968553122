import _ from "lodash";

export default {
  GET_STAGES: state => state.stages,
  GET_FIRST: state => state.first,
  GET_SECONDS: state => state.seconds,
  GET_BLOB: state => state.blob,
  GET_PROFILE: state => state.profile,
  GET_REDIRECT: state => state.redirect,
  GET_QUERY_PARAMS: state => state.queryParams
};