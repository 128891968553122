export default {
  isLoggedIn: (state) => !!state.token,
  roles: (state) => state.roles,
  exp: (state) => state.exp,
  id: (state) => state.id,
  refresh: (state) => state.refresh,
  email: (state) => state.email,
  credentials: (state) => state.credentials,
  loading: (state) => state.loading,
}
