import * as types from './mutations-type'

export default {
  [types.SET_STAGES](state, payload) {
    state.stages = payload
  },

  [types.SET_FIRST](state, payload) {
    state.first = payload
  },

  [types.SET_SECONDS](state, payload) {
    state.seconds = payload
  },

  [types.SET_BLOB](state, payload) {
    state.blob = payload
  },

  [types.SET_PROFILE](state, payload) {
    state.profile = payload
  },

  [types.RESTORE_STORE](state) {
    state.first = null
    state.seconds = []
    state.stages = []
    state.profile.secondaryLogo = null
  },
  [types.SET_REDIRECT](state, payload) {
    state.redirect = payload
  },
  [types.SET_QUERY_PARAMS](state, payload) {
    state.queryParams = payload
  },
};