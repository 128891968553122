<template>
  <router-view v-slot="{ Component }">
    <v-fade-transition>
      <component :is="Component" />
    </v-fade-transition>
  </router-view>
</template>

<script>
export default {
  name: 'App',

  title: 'Traceability – Sou de Algodão',

  created() {
    localStorage.removeItem('vuex');

    let mapbox = require('@mapbox/mapbox-gl-supported');
    if (mapbox.supported()) {
      console.log('MAPBOX_SUPPORTED')
    }
  }
};
</script>

<style>
.Vue-Toastification__toast {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 500;
}

.Vue-Toastification__toast--success {
  border-left: 6px solid #45e1e1 !important;
  background-color: #309999 !important;
}

.Vue-Toastification__toast--warning {
  border-left: 6px solid #ffdba1 !important;
  background-color: #fdbb13 !important;
}

.Vue-Toastification__toast--error {
  border-left: 6px solid #ec6060 !important;
  background-color: #ec3c3c !important;
}

.Vue-Toastification__icon {
  font-size: 25px !important;
}
</style>
