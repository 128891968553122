export default {
  first: null,
  seconds: [],
  stages: [],
  blob: null,
  profile: {
    primaryLogo: require('@/assets/logos/logo-souabr.svg'),
    secondaryLogo: null,
    supportLogos: [
      {
        img: require('@/assets/logos/logo-abrapa.svg'),
        link: 'https://www.abrapa.com.br/Paginas/default.aspx'
      }, {
        img: require('@/assets/logos/logo-iba.svg'),
        link: 'http://iba-br.com/site/'
      }
    ],
    footerLogos: [
      require('@/assets/logos/logo_Abrapa.png'),
      require('@/assets/logos/logo_IBA.png')
    ],
    lang: 'pr-br',
    dataLayout: {
      id: null,
      name: null
    },
    color: null,
    pictures: [],
    video: {
      url: 'https://www.youtube.com/watch?v=1l1h-MWC_e4&ab_channel=SoudeAlgod%C3%A3o',
      thumbnail: require('@/assets/soua/video-frame.jpg')
    }
  },
  redirect: null,
  queryParams: {
    lot: null,
    gtin: null,
    owner: null,
    reportType: null
  },
}