export default {
  roles: localStorage.getItem('token')
    ? JSON.parse(localStorage.getItem('roles'))
    : [],
  exp: '',
  id: '',
  refresh: false,
  refresh_token: localStorage.getItem('refresh_token') || '',
  token: localStorage.getItem('token') || '',
  email: '',
  credentials: {
    email: '',
    password: '',
  },
  loading: false,
}
