export default {
  GET_LOCALE: state => state.locale,
  GET_CURRENT_ROUTE: state => state.currentRoute,
  GET_NAVIGATION_DRAWER: state => state.drawer,
  GET_VIEW: state => state.view,
  GET_VIEWS: () => [{
    name: 'Visualização por fazenda',
    value: 'CustomView'
  }, {
    name: 'Visualização por lista',
    value: 'DataTableView'
  }],
  GET_DIALOG_LOCATION: state => state.dialog.location
};