export default function truncate (text, length, clamp = 0, mode = true) {
    if(length === -1)
        return text

    clamp = clamp || '...'
    let node = document.createElement('div')
    node.innerHTML = text
    let { textContent: content } = node

    if (mode)
        return content && content.length > length
             ? content.slice(0, length) + clamp
             : content
    else
        return `${content.substring(0, length)}...${content.substring(
                  content.length - length,
                  content.length
        )}`;
}