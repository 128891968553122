import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes'
import store from "@/store";

Vue.use(VueRouter)

const scrollBehavior = (to, from, savedPosition) => {
  if (to.name !== from.name) {
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
}

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior
})

const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored
  next()
}
router.beforeEach(waitForStorageToBeReady)

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index+1)

    subsequentMiddleware({...context, next: nextMiddleware});
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({...context, next: nextMiddleware});
  }

  return next();
});

// Change locale in route parameter
/*const changeLocale = async (to, from, next) => {
  if (to.params.lang) {
    Vue.config.lang = to.params.lang
    i18n.locale = to.params.lang
    next()
  } else {
    next('/' + i18n.locale)
  }
}
router.beforeEach(changeLocale)*/

export default router
