import {default as http} from "@/services/HttpService";

class TraceabilityService {
  constructor() {
    this.path = '/v1'
  }

  fetchTraceability(data) {
    return http.client.post(`${this.path}/general-traceability/report`, data)
  }

  fetchVisit(data) {
    return http.client.post(`${this.path}/traceability_saved/visit`, data)
  }

  fetchLogo(cc) {
    return http.client.get(`${this.path}/cc/${cc}`)
  }

  fetchCertificate(id) {
    return http.client.get(`${this.path}/reports/file/${id}`, {
      responseType: 'blob'
    })
  }

  fetchBlockchainHash(ids) {
    return http.client.post(`${this.path}/general-traceability/get_hash_qr`, {
      recordIds: ids
    })
  }

  fetchUrlRedirect(payload) {
    if(payload.shortUrl){
      return http.client.get(`${this.path}/qr-code/get_redirect_url_by_id/${payload.key}`)
    } else {
      return http.client.get(`${this.path}/qr-code/get_redirect_url/${payload.key}`)
    }
  }

  fetchUrlData(reportCode) {
    return http.client.get(`${this.path}/traceability/${reportCode}`)
  }

  // fetchBlockchainHash(ids) {
  //   return axios.get('hash.json')
  // }
}

export default new TraceabilityService()