/* eslint-disable camelcase */
import { max, min, email, numeric, confirmed, alpha_spaces, required } from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'

extend('moreThanZero', {
  validate (value) {
    return value >= 1
  },
  message: 'Escoja un valor válido.'
})

extend('positive', {
  validate (value) {
    return value >= 0
  },
  message: 'Escoja un valor válido.'
})

extend('mayores', {
  validate (value) {
    return value >= 0
  },
  message: 'Introduzca un número de 12 dígitos.'
})

extend('required', {
  ...required,
  message: 'Required field'
})

extend('max', {
  ...max,
  message: 'Introduzca un número de no más de {length} dígitos.'
})

extend('min', {
  ...min,
  message: 'El mínimo número de dígitos es {length}'
})

/// Date validators///

extend('afterToday', {
  validate (value) {
    const [day, month, year] = value.split('/')
    const date = new Date(`${month}/${day}/${year}`)
    const today = new Date()
    return date.getTime() >= today.getTime()
  },
  message: 'Escoja una fecha posterior a la actual.'
})

extend('afterCurrentTime', {
  validate (value) {
    const time = value.split(' ')[1]
    const today = new Date()
    const currentTime = today.getHours() + ':' + today.getMinutes()

    console.log(time)
    console.log(currentTime)

    return time >= currentTime
  },
  message: 'Escoja una fecha posterior a la actual.'
})

extend('after', {
  params: ['target'],
  validate (value, { target }) {
    var [day, month, year] = target.split('/')
    const from = new Date(`${month}/${day}/${year}`)

    var [day2, month2, year2] = value.split('/')
    const until = new Date(`${month2}/${day2}/${year2}`)
    return until.getTime() >= from.getTime()
  },
  message: 'Debe elegir una fecha posterior a la ida.'
})

// Password

extend('confirmPassword', {
  params: ['target'],
  validate (value, { target }) {
    return value === target
  },
  message: 'Passwords not match'
})

extend('confirmed', {
  ...confirmed,
  message: 'Las contraseñas no coinciden'
})

extend('email', {
  ...email,
  message: 'Introduzca un correo válido'
})

extend('numeric', {
  ...numeric,
  message: 'Only accept numbers'
})

extend('alpha_spaces', {
  ...alpha_spaces,
  message: 'Only accept letters'
})

extend('ci', {
  validate (value) {
    const ciRegex = /[0-9]{2}(?:0[0-9]|1[0-2])(?:0[1-9]|[12][0-9]|3[01])[0-9]{5}/
    if (value == null || ciRegex.test(value)) {
      return true
    }
  },
  message: 'Escriba un número de carnet válido'
})

extend('phoneNumber', {
  validate (value) {
    const dateRegex = /^(\+?)\d+$/
    if (value == null || dateRegex.test(value)) {
      return true
    }
  },
  message: 'Escriba un número de teléfono válido'
})

extend('euPhoneNumber', {
  validate (value) {
    if (value == null || value.length > 16) {
      return true
    }
  },
  message: 'Escriba un número de teléfono válido'
})

extend('phoneNumberWithCode', {
  validate (value, args) {
    const final = (args[0] + value).toString().length
    // FIJO
    if (args[1] === 'fijo' && ((args[0] === '+53' && final === 15) || (args[0] === '+53' && final === 16))) {
      return true
    }
    // MOVIL
    if (value == null || (args[0] === '+1' && final === 16) || (args[0] === '+53' && final === 15)) {
      return true
    }
  },
  message: 'Escriba un número de teléfono válido'
})

extend('euPostalCode', {
  validate (value) {
    if (value.length === 2 || value.length === 3 || value.length === 4 || value.length === 5 || value.length === 7 || value.length === 10) {
      return true
    }
  },
  message: 'Escriba un código postal válido'
})

extend('euPostalCode2', {
  validate (value) {
    const dateRegex = /^[A-Z0-9'-'-' ']+$/
    if (value == null || dateRegex.test(value)) {
      return true
    }
  },
  message: 'Escriba las letras en mayúsculas'
})

extend('webSite', {
  validate (value) {
    if (value == null) {
      return true
    }
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
    return pattern.test(String(value))
  },
  message: 'Dirección de enlace no válida'
})

/*const parseTime = (time) => {
  if (time) {
    var hours = time.split(':')[0]
    var minutes = time.split(':')[1]
    var ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12
    hours = hours || 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? minutes : minutes
    var strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
  }
}*/
