import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  options: {
    customProperties: true
  },
  icons: {
    iconfont: 'mdiSvg' || 'mdi' || 'midLight' || 'mdiSvg' || 'md'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#62b3c3',
        secondary: '#173540',
        warning: '#fdbb13',
        error: '#ec3c3c',
        grey: '#707070',
        ground: '#efefef'
      },
      dark: {
        primary: '#62b3c3',
        secondary: '#173540',
        warning: '#fdbb13',
        error: '#ec3c3c',
        grey: '#707070',
        ground: '#efefef'
      }
    }
  }
});
