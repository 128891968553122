import i18n from '@/plugins/i18n'

export default {
  currentRoute: 'login',
  locale: i18n.locale,
  drawer: false,
  view: 'CustomView',
  dialog: {
    location: false
  }
}
