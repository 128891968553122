import AuthService from '@/services/AuthService'
import i18n from '@/plugins/i18n'

export default {
  LOGIN({ commit, state }) {
    return new Promise(async (resolve, reject) => {
      commit('SET_LOADING', true)

      try {
        const { data } = await AuthService.login(state.credentials)
        commit('SET_LOGIN', data)
        resolve(data)
        this._vm.$toast.success(`${i18n.t('auth.notification.login')}`)
      } catch (e) {
        reject(e)
        this._vm.$toast.error(`${i18n.t('auth.notification.loginError')}`)
      } finally {
        commit('SET_LOADING', false)
      }
    })
  },

  LOGOUT({ commit }) {
    commit('SET_LOGOUT')

    this.$toast.success(i18n.t('auth.notification.logout'), {
      queueable: true,
      color: '#37A98C',
    })
  },

  REFRESH({ commit }, [refresh_token, token]) {
    return new Promise((resolve, reject) => {
      try {
        const { data } = AuthService.refreshToken(refresh_token, token)
        commit('SET_REFRESH', data)
        resolve(data)
      } catch (e) {
        reject(e)
      }
    })
  },
}
