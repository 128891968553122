import rastreio from "@/middleware/router.middleware";

export default [
  {
    path: '/',
    component: () => import('@/layouts/DefaultLayout'),
    children: [{
      path: '/',
      name: 'IndexCompany',
      component: () => import('@/views/Default/IndexView')
    }],
    beforeEnter: rastreio
  },{
    path: '/rastreio',
    component: () => import('@/layouts/DefaultLayout'),
    children: [{
      path: '/rastreio',
      name: 'IndexCompany',
      component: () => import('@/views/Default/IndexView')
    }],
    beforeEnter: rastreio
  },{
    path: '/r',
    component: () => import('@/layouts/DefaultLayout'),
    children: [{
      path: '/r',
      name: 'IndexCompany',
      component: () => import('@/views/Default/IndexView')
    }],
    beforeEnter: rastreio
  },{
    //---------- AUTH ------------
    path: '/auth',
    component: () => import('@/layouts/AuthenticationLayout'),
    children: [{
      path: '/auth/login',
      name: 'Login',
      component: () => import('@/views/Auth/LoginView')
    }]
  },{
    //---------- ADMIN & DOCTOR ------------
    path: '/dashboard',
    component: () => import('@/layouts/DashboardLayout'),
    children: [{
      path: '/dashboard',
      name: 'Dashboard',
      component: () => import('@/views/Dashboard/IndexView')
    }]
  }, {
    path: "*",
    beforeEnter: (to, from, next) => {
      window.location.href = "https://soudealgodao.com.br/souabr/"
    }
  }
]