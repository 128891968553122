import { default as http } from './HttpService'
import _ from "lodash";
import i18n from '@/plugins/i18n'

class TranslationService {
  constructor() {}

  capitalizeFirstLetter(text) {
    return text.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())
  }

  langKeys() {
    return http.client.get(`v1/lang-key`)
  }

  async langStructure() {
    return await http.client.get(`v1/lang-key/structure`)
  }

  getLangName() {
    return this.getLangNameByKey(i18n.locale)
  }

  getLangNameByKey(langKey) {
    let lang = ''
    switch (langKey) {
      case 'pt-br':
        lang = 'PORTUGUESE'
        break
      case 'en-us':
        lang = 'ENGLISH'
        break
      case 'es-es':
        lang = 'ESPANNOL'
    }
    return lang
  }

  getKeyByLangName(langName) {
    let key = ''
    switch (langName) {
      case 'PORTUGUESE':
        key = 'pt-br'
        break
      case 'ENGLISH':
        key = 'en-us'
        break
      case 'ESPANNOL':
        key = 'es-es'
    }
    return key
  }

  changeLang(langName) {
    i18n.locale = this.getKeyByLangName(langName)
  }

  getKey(key) {
    let lang = this.getLangName()
    let translates = JSON.parse(localStorage.getItem('lang_keys'))
    return translates[lang][key] ? translates[lang][key] : false
  }

  getKey2(key, uppercase) {
    let lang = this.getLangName()
    let translates = JSON.parse(localStorage.getItem('lang_keys'))[lang]
    return translates[key]
      ? uppercase
        ? translates[key].toUpperCase()
        : translates[key]
      : key
  }

  getKey3(translatesArrayText, uppercase) {
    if (translatesArrayText) {
      let lang = this.getLangName()
      let translates = _.find(translatesArrayText, { language: lang })
      return translates
        ? uppercase
          ? translates.label.toUpperCase()
          : translates.label
        : translatesArrayText
    } else {
      return null
    }
  }

  setKey3(key, translatesArrayText, langStructure) {
    let lang = this.getLangName()
    if (langStructure) translatesArrayText = langStructure

    if (key === null) key = ''

    return _.map(translatesArrayText, (translate) => {
      return {
        ...translate,
        label:
          translate.language === lang ? key : langStructure ? key : translate.label,
      }
    })
  }
}

export default new TranslationService()
